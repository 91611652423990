import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import theme from "../../../theme";
import erawan_orange from "../../../assets/erawan_elephant_orange.svg";
import {makeStyles} from "@mui/styles";
import SACCPaper from "../../../common/SACC/SACCPaper";
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from "universal-cookie";
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown";
import {basicStyle} from "../../../common/SACC/CareersFairContext/CFASStyles";
import {CFASProfileEducationSection} from "../../../common/SACC/CareersFairContext/CFASProfileEducationSection";
import {Colours, SamaggiTypography} from "../../../common/SamaggiTypography";

const useStyles = makeStyles(() => basicStyle);

const answerStyle = {
    fontWeight : "400",
    fontSize : "14px",
    border: "2px solid #A2A2A2",
    borderRadius : "15px",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
    height: 200
}

const Profile = (props) => {

    const {companyViewApplication, checkToken, updateStatus, updateMessage, saveNote} = useAuth();
    const classes = useStyles();

    const cookies = new Cookies();

    const [loaded, setLoaded] = useState(false);
    const [cvLink, setCvLink] = useState("");
    const [coverLink, setCoverLink] = useState("");
    const [profileData, setProfileData] = useState({});
    const [userData, setUserData] = useState({});
    const [applicationData, setApplicationData] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const [positionData, setPositionData] = useState({});
    const [status, setStatus] = useState("SUBMITTED");
    const [message, setMessage] = useState("No Message");
    const [transcriptLink, setTranscriptLink] = useState("");
    const [note, setNote] = useState("");
    const [workExperience, setWorkExperience] = useState([
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
        { employer: "", country: "", position: "", dateFrom: "", dateTo: "", industry: "" },
    ]);

    const [updating, setUpdating] = useState(false);
    const [saving, setSaving] = useState(false);

    function handleCVButton() {
        if (cvLink !== "") {
            console.log(cvLink);
            window.open(cvLink, "_blank");
        }
    }

    function handleCoverButton() {
        if (coverLink !== "") {
            console.log(coverLink);
            window.open(coverLink, "_blank");
        }
    }

    function handleTranscriptButton() {
        if (coverLink !== "") {
            console.log(transcriptLink);
            window.open(transcriptLink, "_blank");
        }
    }

    function getData(applicationID) {
        const companyID = cookies.get("companyID");
        const tokenID = cookies.get("cTokenID");
        const secret = cookies.get("cSecret");

        companyViewApplication(applicationID, tokenID, secret, companyID).then(async (res) => {
            const data = await res.json()
            props.setShowCover(false);
            if (res.status === 200) {
                setCvLink(data.data["cvLink"]);

                if (Object.keys(data.data).includes("application_data")) {
                    setApplicationData(data.data["application_data"]);
                    const tApplication = data.data["application_data"];
                    setCoverLink(tApplication["coverFile"]);
                    setStatus(tApplication["status"]);

                    if (Object.keys(tApplication).includes("message") && tApplication["message"] !== "") {
                        setMessage(tApplication["message"]);
                    }
                    if (Object.keys(tApplication).includes("note") && tApplication["note"] !== "") {
                        setNote(tApplication["note"]);
                    }
                }
                if (Object.keys(data.data).includes("user_data")) {
                    setUserData(data.data["user_data"]);
                }
                if (Object.keys(data.data).includes("profile_data")) {
                    setProfileData(data.data["profile_data"]);
                    setCvLink(data.data["profile_data"]["cvLink"]);
                    setWorkExperience(data.data["profile_data"]["workExperience"] || []);
                    setTranscriptLink(data.data["profile_data"]["transcriptLink"]);
                }
                if (Object.keys(data.data).includes("position_data")) {
                    setPositionData(data.data["position_data"]);
                }

                setLoaded(true);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            props.setShowCover(false);
            console.log(error)
        })
    }

    function handleStatusChange(e) {
        const companyID = cookies.get("companyID");
        const tokenID = cookies.get("cTokenID");
        const secret = cookies.get("cSecret");

        const urlParams = new URLSearchParams(window.location.search);
        const applicationID = urlParams.get('applicationID');

        setStatus(e.target.value);

        updateStatus(companyID, tokenID, secret, applicationID, e.target.value).then(async (res) => {
            const data = await res.json()
            if (res.status === 200) {
                alert("Status Updated");
                getData(applicationID);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    function handleSaveMessage() {
        const companyID = cookies.get("companyID");
        const tokenID = cookies.get("cTokenID");
        const secret = cookies.get("cSecret");

        const urlParams = new URLSearchParams(window.location.search);
        const applicationID = urlParams.get('applicationID');

        setUpdating(true);

        updateMessage(companyID, tokenID, secret, applicationID, message).then(async (res) => {
            const data = await res.json()
            setUpdating(false);
            if (res.status === 200) {
                alert("Message Updated");
                getData(applicationID);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            setUpdating(false);
            console.log(error)
        })
    }

    function handleSaveNote() {
        const companyID = cookies.get("companyID");
        const tokenID = cookies.get("cTokenID");
        const secret = cookies.get("cSecret");

        const urlParams = new URLSearchParams(window.location.search);
        const applicationID = urlParams.get('applicationID');

        setSaving(true);

        saveNote(companyID, tokenID, secret, applicationID, note).then(async (res) => {
            const data = await res.json()
            setSaving(false);
            if (res.status === 200) {
                alert("Note Saved");
                getData(applicationID);
            } else {
                alert(data.data["reason"]);
            }
        }).catch((error) => {
            setSaving(false);
            console.log(error)
        })
    }

    useEffect(() => {
        if (firstLoad) {
            const urlParams = new URLSearchParams(window.location.search);
            const applicationID = urlParams.get('applicationID');

            const companyID = cookies.get("companyID");
            const tokenID = cookies.get("cTokenID");
            const secret = cookies.get("cSecret");

            checkToken("/cfas/company/view-profile?applicationID=" + applicationID, companyID, tokenID, secret, true);

            props.setShowCover(true);

            getData(applicationID);
            setFirstLoad(false);
        }
    })

    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    function handleBack() {
        window.location = "/cfas/company/home"
    }

    return (
        <ThemeProvider theme={theme}>
            {loaded && <Grid container direction="column" justifyContent="center" padding="100px" rowSpacing={1}>
                <Grid container item justifyContent="space-between">
                    <Grid item>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item>
                                <img className={classes.logo} src={"/logo_green.jpg"} alt={"Logo"}/>
                            </Grid>
                            <Grid item>
                            <Typography className={classes.applicationTitle} variant="h5">
                                    CFAS for Companies
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container alignItems="center" direction={"row"} columnSpacing={4}>
                        <Grid item xs={6}>
                            <Button className={classes.logoffButton} onClick={handleBack}>Back to Dashboard</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className={classes.logoffButton}>Log Out</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    {loaded && (
                    <SACCPaper sx={{marginTop: 5, paddingBottom: 10, marginBottom: 4}}>
                        <Typography gutterBottom variant="h6" className={classes.title}>
                            Personal Information
                        </Typography>
                        <Grid container direction={largeScreen ? "row" : "column"} justifyContent={"space-between"}
                              rowSpacing={2} columnSpacing={3}>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Full Name
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["fullName"]} ({profileData["nickname"]})
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Date of Birth
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["birthdate"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {userData["email"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Line ID
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["lineID"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Phone Number
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        +{profileData["phoneNumber"].replace("//", " ")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Gender
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["gender"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SamaggiTypography gutterBottom variant="h6" bold large sx={{marginTop: 10}} colour={Colours.samaggiGreen}>
                            Education Information
                        </SamaggiTypography>
                        <Grid container item direction={largeScreen?"row":"column"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                            <Grid container item xs={12} lg={6} direction="column">
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        University
                                    </Typography>
                                </Grid>
                                { loaded && (<Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["university"].toString().replace("Other//", "Other – ")}
                                    </Typography>
                                </Grid>)}
                            </Grid>
                            <Grid container item xs={12} lg={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Area
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["courseArea"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Name
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["courseName"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Year of Study
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["year"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={4} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Course Level
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["level"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Expected Graduation
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["expectedGraduation"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Are you considering pursuing further Education?
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["furtherEducation"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Start Date
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["startDate"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        End Date
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["endDate"]}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        GPA
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["gpa"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <SamaggiTypography gutterBottom variant="h6" bold large sx={{marginTop: 10}} colour={Colours.samaggiGreen}>
                            Optional Information
                        </SamaggiTypography>
                        <Grid container item direction={"row"} justifyContent={"space-between"} rowSpacing={3} columnSpacing={4}>
                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Skills
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["skills"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Qualifications and Certifications
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["quali"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Sexual Orientation
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["orientation"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Ethnicity
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["race"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Religion
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["religion"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item md={6} xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Disability
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {profileData["disability"] || "N/A"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SamaggiTypography gutterBottom variant="h6" bold large sx={{marginTop: 10}} colour={Colours.samaggiGreen}>
                            Work Experience
                        </SamaggiTypography>
                        <Grid container direction={largeScreen ? "row" : "column"} spacing={4}>
                            {workExperience.length > 0 ? (
                                workExperience.map((exp, index) => (
                                    <Grid container item spacing={4} key={index}>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Employer
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.employer || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Country
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.country || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Position
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.position || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Industry/Sector
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.industry || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={6} direction="column">
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                                    Employment Dates
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography gutterBottom variant="h6" className={classes.text}>
                                                    {exp.dateFrom || "N/A"} - {exp.dateTo || "N/A"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        No work experience provided.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Typography gutterBottom variant="h6" className={classes.title} sx={{marginTop: 10}}>
                            Application Information
                        </Typography>
                        <Grid container direction={largeScreen ? "row" : "column"} justifyContent={"space-between"}
                              rowSpacing={3} columnSpacing={4}>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Position
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {positionData["position-name"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Application ID
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        {applicationData["application-id"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} direction={"column"}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.subtitle}>
                                        Additional Text
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}
                                                sx={{whiteSpace: "pre-wrap"}}>
                                        {applicationData["additional-text"] === "" ? "The applicant did not enter any additional text" : applicationData["additional-text"]}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SACCPaper>
                    )}
                </Grid>
                <Grid item container direction={"row"} columnSpacing={6} rowSpacing={6}>
                    <Grid item xs={12} xl={6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction={"column"} rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Candidate's CV
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download and view a copy of the candidate's CV.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleCVButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    {applicationData["coverFile"] && applicationData["coverFile"] !== "X" && <Grid item xs={12} xl={6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction={"column"} rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Candidate's Cover Letter
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download and view a copy of the candidate's cover letter.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleCoverButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>}
                    {transcriptLink && transcriptLink !== "X" && <Grid item xs={12} xl={6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3} justifyContent={"space-between"}>
                                <Grid item container direction={"column"} rowSpacing={3}>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                            Candidate's Transcript
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography gutterBottom variant="h6" className={classes.text}>
                                            Please click the button below to download and view a copy of the candidate's transcript.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleTranscriptButton}>Download a Copy</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>}
                    <Grid item xs={12} xl={6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Application Status
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        Select an option below to update the application's status. The applicant will be notified by email when the status changes.
                                    </Typography>
                                </Grid>
                                <Grid item container direction={"column"} rowSpacing={3}>
                                    <Grid item>
                                        <Dropdown items={["SUBMITTED", "INTERVIEW", "UNSUCCESSFUL", "ACCEPTED"]} value={status} onChange={(e) => handleStatusChange(e)}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Message to Applicant
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        Leave a message for the applicant. They will be notified if the message changes and will be able to see the message in CFAS Track.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {loaded && <textarea style={answerStyle} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>}
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleSaveMessage}>{updating ? "Updating" : "Update"}</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                    <Grid item xs={12} xl={(applicationData["coverFile"] && applicationData["coverFile"] !== "X") ? 12 : 6}>
                        <SACCPaper style={{height:'100%', boxSizing: "border-box"}}>
                            <Grid container direction={"column"} rowSpacing={3}>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.titleNoMargin}>
                                        Notes
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom variant="h6" className={classes.text}>
                                        Make a note about this application. This note is only visible to you and will NOT be visible to the applicant.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {loaded && <textarea style={answerStyle} value={note} onChange={(e) => setNote(e.target.value)}></textarea>}
                                </Grid>
                                <Grid item>
                                    <Button className={classes.logoffButton} onClick={handleSaveNote}>{saving ? "Saving" : "Save"}</Button>
                                </Grid>
                            </Grid>
                        </SACCPaper>
                    </Grid>
                </Grid>
            </Grid>}
        </ThemeProvider>
    );
};

export default Profile;