import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import theme from "../../../theme";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useEffect} from "react";

//const medScreen = useMediaQuery(theme.breakpoints.up('md'));

const useStyles = makeStyles(() => ({
  dropdownTitle: {
    fontWeight: 600,
    color: "#A3A3A3",
    fontSize: "24px",
  },

  formControl: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "3px solid #878787",
        borderRadius: "15px",
      },
      "&:hover fieldset": {
        borderColor: "#878787",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
  select: {
    fontWeight: 500,
    color: "#878787",
    fontSize: "24px",
    width: "100%",
    borderRadius: "15px",
  },
  item: {
    fontWeight: "500",
    color: "#878787",
    fontSize: "24px",
  },
}));

const Dropdown = ({ items, label, value, setter, onChange }) => {
  const classes = useStyles();

  const sScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const medScreen = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (!items.includes(value)) {
      if (setter !== undefined && setter !== null) {
        setter(items[0])
      }
    }
  }, [items, value]);

  return (
    <FormControl fullWidth className={classes.formControl}>
      <Typography className={classes.dropdownTitle}>{label}</Typography>
      <Select className={classes.select} value={value} onChange={onChange} defaultValue={items[0]}>
        {items.map((item, index) => (
          <MenuItem sx={item} value={item} key={"sel-" + index.toString()} style={medScreen ? {zoom: "100%"} : {zoom: "150%", marginLeft: "50%"}}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

Dropdown.defaultProps = {
  items: [1, 2, 3],
};

export default Dropdown;
