import theme from "../../../theme";

export const basicStyle = {
    applicationTitle: {
        color: "rgb(0,44,41)",
        fontWeight: "700",
        fontSize: "32px",
    },
    text: {
        color: "#878787",
    },
    logo: {
        width: "50px",
    },
    title: {
        fontWeight: `${theme.typography.fontWeightBold} ! important`,
        color: "rgb(0,44,41)",
        marginBottom: `${theme.spacing(4)} ! important `,
        fontSize: "27px !important"
    },
    titleSmallMargin: {
        fontWeight: `${theme.typography.fontWeightBold} ! important`,
        color: "rgb(0,44,41)",
        marginBottom: `${theme.spacing(2)} ! important `,
        fontSize: "27px !important"
    },
    titleNoMargin: {
        fontWeight: `${theme.typography.fontWeightBold} ! important`,
        color: "rgb(0,44,41)",
        marginBottom: `${theme.spacing(0)} ! important `,
        fontSize: "27px !important"
    },
    logoffButton: {
        background: "rgb(0,44,41) !important",
        color: "white",
        width: "100%",
        padding: "15px",
        borderRadius: "10px !important",
        "&:hover": {
            background: "rgb(0,25,23) !important",
        },
        marginTop: "auto",
        marginBottom: "auto"
    },
    subtitle: {
        fontWeight: `${theme.typography.fontWeightBold} ! important`,
        color: "#606060",
        marginBottom: `${theme.spacing(1)} ! important `,
        marginRight: `${theme.spacing(2)} ! important `,
        fontSize: "21px !important"
    }
};