import {useEffect, useState} from "react";
import { Typography, Grid, ThemeProvider, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Dropdown from "../../../common/SACC/CareersFairContext/Dropdown";
import {courseLevelList, industries} from "../../../assets/options";
import { useAuth } from "../../../contexts/AuthContext";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {truncate} from "../../../TSsupport";

const useStyles = makeStyles(() => ({
  applicationTitle: {
    color: "#FB8A3E",
    fontWeight: 700,
    fontSize: "48px",
  },
  text: {
    color: "#878787",
  },
  logo: {
    width: "60px",
    height: "100px",
  },
  dropdownMenu: {
    padding: theme.spacing(0, 1),
    width: "100%",
    borderRadius: "15px",
    fontSize: "24px",
    fontWeight: 500,
  },
  dropdownTitle: {
    fontWeight: 600,
    color: "#A3A3A3",
    fontSize: "24px",
  },
  companyPaper: {
    padding: theme.spacing(3, 5),
    borderRadius: "40px ! important"
  },
  companyButton: {
    color: "rgb(0, 41, 44)",
    fontSize: "20px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  companyTitle: {
    fontSize: "30px",
    fontWeight: 600,
  },
  companyDescription: {
    fontSize: "20px",
    color: "#5F5F5F",
    fontWeight: 400,
  },
  logoffButton: {
    background: "#FB8A3E",
    color: "white",
    width: "100%",
    padding: "15px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
    "&:hover": {
      background: "#FB8A3E",
    },
  },
  squareContainer: {
    padding: "10px",
    boxSizing: "border-box",
    overflow: "auto",
    maxWidth: "100%",
    aspectRatio: 1
  }
}));

const ApplyHome = (props) => {
  const {listRoles, companyInfo} = useAuth();
  const classes = useStyles();
  const [industry, setIndustry] = useState("All");
  const [education, setEducation] = useState("Any");
  const [term, setTerm] = useState("Any");
  const [eligibleOnly, _] = useState("No");

  const [companiesList, setCompaniesList] = useState([]);
  const [companyData, setCompanyData] = useState({});

  const dropdownMenus = [
    {
      title: "Industry",
      options: ["All", ...industries],
      value: industry,
      setter: setIndustry
    },
    {
      title: "Current Education",
      options: ["Any", ...courseLevelList],
      value: education,
      setter: setEducation
    },
    {
      title: "Term",
      options: ["Any", "Full-Time (Graduate)", "Full-Time (Experienced-Hire)", "Internship"],
      value: term,
      setter: setTerm
    }
  ];

  const [loading, setLoading] = useState(true);
  const [empty, setEmpty] = useState(false);

  const termMap = {
    "Internship": "INTERNSHIP",
    "Full-Time (Graduate)": "FULLTIMEGRAD",
    "Full-Time (Experienced-Hire)": "FULLTIMEEXP"
  }

  const educationMap = {
    "Undergraduate": "UNDERGRADUATE",
    "Master's": "MASTERS",
    "Doctorate": "DOCTORATE",
    "Post-doctorate": "POSTDOCTORATE"
  }

  function load() {
    let filters = [];

    if (industry !== "All") {
      filters.push({
        "field": "industry",
        "filterType": "CONTAIN",
        "value": industry,
        "includesEmpty": false
      })
    }
    if (education !== "Any") {
      filters.push({
        "field": "education-level",
        "filterType": "CONTAIN",
        "value": educationMap[education],
        "includesEmpty": false
      })
    }
    if (term !== "Any") {
      filters.push({
        "field": "term",
        "filterType": "CONTAIN",
        "value": termMap[term],
        "includesEmpty": false
      })
    }

    console.log(filters);

    listRoles(filters, eligibleOnly).then(async (res) => {
      const data = await res.json()
      console.log(res)
      console.log(data)
      if (res.status === 200) {
        if (data.data.roles.length === 0) {
          setEmpty(true);
          setLoading(false);
        } else {
          setEmpty(false);
        }

        const companyPriority = []

        for (let i = 0; i < data.data.roles.length; i++) {
          const companyID = data.data.roles[i]["company-id"];
          if (!(companiesList.includes(companyID))) {
            companyPriority.push({name: companyID, priority: data.data.roles[i]["priority"]});
            companiesList.push(companyID);

            companyInfo(companyID).then(async (companyRes) => {
              console.log(companiesList.indexOf(companyID));
              if (companyRes.status === 200) {
                let cd = companyData;
                cd[companyID] = (await companyRes.json()).data;
                setCompanyData(cd);
              } else {
                console.warn("Failed to load : " + companyID);
                companiesList.splice(companiesList.indexOf(companyID), 1);
              }

              if (Object.keys(companyData).length === companiesList.length) {
                setLoading(false);
                let sortedPriority = Object.values(companyData).sort((a, b) =>
                    a.priority - b.priority || a["company-name"].localeCompare(b["company-name"])
                );
                const cN = sortedPriority.map(company => company["company-id"]);
                console.log(cN);
                console.log(sortedPriority)
                console.log(companiesList);
                setCompaniesList(cN);
              }
            }).catch((error) => {
              console.log(error)
            })
          }
        }

        console.log(companiesList)
        let sortedPriority = companyPriority.sort((a, b) =>
            a.priority - b.priority || a.name.localeCompare(b.name)
        );

        setCompaniesList(sortedPriority.map(({name}) => name));
        console.log(sortedPriority)
        // shuffle(companiesList, data.data["randomKey"]);
      } else {
        alert(data.data["reason"]);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  function shuffle(array, seed) {
    let m = array.length, t, i;

    while (m) {
      i = Math.floor(random(seed) * m--);

      t = array[m];
      array[m] = array[i];
      array[i] = t;
      ++seed
    }

    return array;
  }

  function random(seed) {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  }

  function reload(setter, event) {
    setCompaniesList([]);
    setCompanyData({});
    setLoading(true);
    setter(event.target.value);
    setFirstLoad(true);
  }

  function viewRoles(companyID) {
    window.location = "/cfas/apply/company?companyID=" + companyID;
  }

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      load()
      setFirstLoad(false);
      props.setShowCover(false);
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        padding="100px"
        spacing={4}
        rowSpacing={7}
      >
        <CFASHeader classes={classes} secondaryURL={"/cfas/hub/home"} secondaryText={"Back to Hub"}>
          CFAS Apply
        </CFASHeader>
        <Grid container item justifyContent="left" columnSpacing={3} rowSpacing={1}>
          {dropdownMenus.map((item) => (
            <Grid item xs={12} md={8} lg={6}>
              <Dropdown items={item.options} value={item.value} label={item.title} onChange={(e) => reload(item.setter, e)}/>
            </Grid>
          ))}
        </Grid>
        {loading && !empty && (
            <Paper className={classes.companyPaper} sx={{width: "100%", marginTop: 6}} elevation={3}>
              <Typography className={classes.companyTitle} sx={{textAlign: "center", marginBottom: 2}}>
                Loading...
              </Typography>
              <Typography className={classes.companyDescription} sx={{textAlign: "center"}}>
                Please wait as we load available companies
              </Typography>
            </Paper>
        )}
        {!loading && empty && (
            <Paper className={classes.companyPaper} sx={{width: "100%", marginTop: 6}} elevation={3}>
              <Typography className={classes.companyTitle} sx={{textAlign: "center", marginBottom: 2}}>
                No Position Available
              </Typography>
              <Typography className={classes.companyDescription} sx={{textAlign: "center"}}>
                Unfortunately no company offer positions matching your filter. Please change your filters and try again.
              </Typography>
            </Paper>
        )}
        {!loading && !empty && (
            <Grid item container spacing={5} rowSpacing={10} direction={"row"} alignItems="stretch">
              {companiesList.map((company) => (
                  <Grid item xs={12} lg={6} xl={4}>
                    <Paper className={classes.companyPaper} elevation={6} style={{height:'100%'}}>
                      <Grid container direction="column" justifyContent="space-between" alignItems="stretch" rowSpacing={1} height={"100%"}>
                        <Grid item>
                          <Grid item container direction={"row"} alignContent={"center"} justifyContent={"center"} marginBottom={4} className={classes.squareContainer}>
                            <img style={{objectFit: "contain", maxWidth: "90%", height:"90%"}} src={companyData[company] === undefined ? "" :companyData[company]["logo"]} alt={"Company Logo"}/>
                          </Grid>
                          <Grid item container direction={"column"} justifyContent={"space-between"}>
                            <Grid item container direction={"column"} rowSpacing={2}>
                              <Grid item>
                                <Typography className={classes.companyTitle}>
                                  {companyData[company]["company-name"]}
                                </Typography>
                              </Grid>
                              <Grid item marginBottom={5}>
                                <Typography className={classes.companyDescription} dangerouslySetInnerHTML={{__html: truncate(companyData[company]["company-description"], 350)}}>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Button endIcon={<ArrowRightAltIcon />} className={classes.companyButton} onClick={() => viewRoles(company)}>
                            View Positions
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
              ))}
            </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

export default ApplyHome;
