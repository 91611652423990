import { makeStyles } from "@mui/styles";
import theme from "../../theme";
import SACCPaper from "./SACCPaper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import SACCTextButton from "./SACCTextButton";
import {Colours, SamaggiTypography} from "../SamaggiTypography";

const useStyles = makeStyles(() => ({
  content: {
    padding: theme.spacing(10, 25),
  },
  title: {
    fontWeight: `${theme.typography.fontWeightBold} ! important`,
    color : "#FB8A3E"
  },
}));

const paragraph1 = "The annual Samaggi Academic Conference and Careers Fair (SACC) is back for 2024, now reaching its 16th edition. The series of events provide a medium for Thai students of all educational backgrounds in the United Kingdom (undergraduate, postgraduate, and doctoral studies) to connect and exchange their knowledge and ideas with other attendees, alongside exploring their potential career opportunities. An important part of this is the invitation of representatives from a variety of organisations to help students expand their long-lasting academic and professional network as well as learn from a panel of experts in their respective fields . SACC 2024 will consist of two events held together, the careers fair and the Samaggi seminar, on the 20th January 2024 at the Brunei Gallery, SOAS University of London."
	
const paragraph2 = "This year’s theme that will connect both the careers fair and academic conference is “Learning From the Past, Addressing the Present, and Embracing the Future”. We hope that through this theme, we are able to capture the importance of new and emerging technologies and ideas as well as paying attention to the care we need to consider as society tries to navigate such a dynamic and fast-changing world."

const SACCCareersPaper = () => {
  const classes = useStyles();
  return (
      <SACCPaper>
        <Grid container spacing={3}>
          <Grid item>
            <SamaggiTypography large bold colour={Colours.samaggiGreen}>What is SACC?</SamaggiTypography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <SamaggiTypography>{paragraph1} </SamaggiTypography>
            </Grid>
            <Grid item>
              <SamaggiTypography>{paragraph2} </SamaggiTypography>
            </Grid>
          </Grid>
        </Grid>
      </SACCPaper>
  )
}
export default SACCCareersPaper;
