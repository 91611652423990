import {Button, Grid, ThemeProvider, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@mui/styles";
import theme from "../../../theme";
import {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import {CFASHeader} from "../../../common/SACC/CareersFairContext/CFASHeader";
import {truncate} from "../../../TSsupport";

const useStyles = makeStyles(() => ({
  applicationTitle: {
    color: "rgb(0, 41, 44)",
    fontWeight: 700,
    fontSize: "48px",
  },
  text: {
    color: "#878787",
  },
  logo: {
    width: "60px",
    height: "100px",
  },
  rolePaper: {
    padding: theme.spacing(8),
    paddingBottom: theme.spacing(0),
    borderRadius: "40px ! important",
  },
  logoffButton: {
    background: "#FB8A3E",
    color: "white",
    width: "100%",
    padding: "15px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "10px",
    "&:hover": {
      background: "#FB8A3E",
    },
  },
  roleTitle: {
    fontSize: "26px",
    fontWeight: 600,
    color: "#000000",
  },
  eligible: {
    fontSize: "18px",
    color: "rgb(0, 41, 44)",
    fontWeight: 600,
  },
  roleDescription: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#5F5F5F",
  },
  roleHeading: {
    fontSize: "32px",
    color: "rgb(0, 41, 44)",
    fontWeight: 700,
  },
  applyButton: {
    color: "rgb(0, 41, 44)",
    border: "2px solid rgb(0, 41, 44)",
    padding: "10px 40px",
    fontSize: "16px",
    fontWeight: 600,
    "&:hover": {
      border: "2px solid rgb(0, 41, 44)",
      backgroundColor: "transparent",
    },
  },
  companyName: {
    fontWeight: 600,
    fontSize: "40px",
    color: " #000000",
  },
  companyDescription: {
    color: "#3D3D3D",
    fontSize: "24px",
    fontWeight: 500,
  },
  loadPaper: {
    padding: theme.spacing(3, 5),
    borderRadius: "40px ! important",
  },
}));


const ApplyCompany = (props) => {
  const classes = useStyles();
  const {checkEligiblePos, companyInfo} = useAuth();

  const [companyData, setCompanyData] = useState({});
  const [rolesData, setRolesData] = useState({});
  const [rolesLoaded, setRolesLoaded] = useState(false);

  const reasonMap = {
    "courseArea": "Course Area",
    "level": "Education Level",
    "gender": "Gender",
    "isOpen": "Application Closed"
  }

  function load(companyID) {
    companyInfo(companyID).then(async (companyRes) => {
      const data = await companyRes.json();
      if (companyRes.status === 200) {
        setCompanyData(data.data);
      } else {
        alert(data.data["reason"]);
      }
    }).catch((error) => {
      console.log(error)
    })

    checkEligiblePos(companyID).then(async (rolesRes) => {
      const data = await rolesRes.json();
      if (rolesRes.status === 200) {
        setRolesData(data.data);
        setRolesLoaded(true);
        console.log(data)
      } else {
        alert(data.data["reason"]);
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      const urlParams = new URLSearchParams(window.location.search);
      const companyID = urlParams.get('companyID');

      load(companyID);
      props.setShowCover(false);
      setFirstLoad(false);
    }
  })

  function handleClick(roleID) {
    window.location = "/cfas/apply/submit?roleID=" + roleID;
  }

  const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ThemeProvider theme={theme}>
      <Grid
          container
          direction="column"
          justifyContent="center"
          padding="100px"
          rowSpacing={5}
      >
        <CFASHeader classes={classes} secondaryURL={"/cfas/apply/home"} secondaryText={"Back to List"}>
          CFAS Apply
        </CFASHeader>
        <Grid item container direction="row" rowSpacing={2} columnSpacing={3}>
          <Grid item marginBottom={3} xs={12} lg={3}>
            <img src={companyData["logo"]} style={{maxWidth: "100%"}} alt="Company Logo"></img>
          </Grid>
          <Grid item container direction={"column"} xs={12} justifyContent={"center"}>
            <Grid item container direction={"column"}>
              <Grid item>
                <Typography className={classes.companyName}>
                  {companyData["company-name"]}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.companyDescription} sx={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{__html: companyData["company-description-long"] ? companyData["company-description-long"] : companyData["company-description"]}}>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="column" rowSpacing={4}>
          <Grid item>
            <Typography className={classes.roleHeading}>Eligible Positions</Typography>
          </Grid>
          <Grid item container spacing={6} rowSpacing={14} direction={"row"} alignItems="stretch">
            {!rolesLoaded && (
                <Grid item xs={12}>
                  <Paper className={classes.loadPaper} sx={{width: "100%", marginTop: 10}} elevation={3}>
                    <Typography className={classes.roleTitle} sx={{textAlign: "center", marginBottom: 2}}>
                      Loading...
                    </Typography>
                    <Typography className={classes.roleDescription} sx={{textAlign: "center"}}>
                      Please wait as we load available positions
                    </Typography>
                  </Paper>
                </Grid>
            )}
            {rolesLoaded && rolesData["eligible"].map((role) => (
                <Grid item xs={12} lg={6}>
                  <Paper elevation={6} className={classes.rolePaper} style={{height: '100%'}}>
                    <Grid container direction="column" alignItems="center" justifyContent={"space-between"}
                          rowSpacing={5} style={{height: "100%"}}>
                      <Grid item width={"100%"}>
                        <Grid item container alignItems="left" direction={"column"} spacing={4} rowSpacing={3}
                              width={"100%"}>
                          <Grid item container direction={"row"} justifyContent={"space-evenly"} columnSpacing={4}>
                            <Grid item xs={10} lg={12} xl={10}>
                              <Typography className={classes.roleTitle}>
                                {role["position-name"]}
                              </Typography>
                            </Grid>
                            <Grid item container direction={"column"} xs={2} lg={12} xl={2} justifyContent={"center"}
                                  marginTop={(!xlScreen && largeScreen) ? 3 : 0}>
                              <Typography className={classes.eligible}
                                          textAlign={(!xlScreen && largeScreen) ? "center" : "right"}>
                                {!role["applied"] ? "(Eligible)" : "(Already Applied)"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Typography className={classes.roleDescription} sx={{whiteSpace: "pre-wrap"}} display={"block"} dangerouslySetInnerHTML={{__html: truncate(role["position-description"].includes("//") ? role["position-description"].substring(0, role["position-description"].indexOf("//")) : role["position-description"], 500)}}>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {!role["applied"] && <Grid item>
                        <Grid item>
                          <Button variant="outlined" className={classes.applyButton}
                                  onClick={() => handleClick(role["position-id"])} size="large">
                            Apply Now
                          </Button>
                        </Grid>
                      </Grid>}
                    </Grid>
                  </Paper>
                </Grid>
            ))}
          </Grid>
          {rolesData["notEligible"] && rolesData["notEligible"].length > 0 &&
              <Grid item container direction="column" rowSpacing={2} marginTop={10}>
                <Grid item>
                  <Typography className={classes.roleHeading}>Other Positions</Typography>
                </Grid>
                <Grid item container spacing={4} rowSpacing={16} direction={"row"} alignItems="stretch">
                  {rolesLoaded && rolesData["notEligible"].map((role) => (
                      <Grid item xs={12} lg={6}>
                        <Paper elevation={6} className={classes.rolePaper} style={{height: '100%'}}>
                          <Grid container direction="column" alignItems="center" justifyContent={"space-between"}
                                rowSpacing={5} style={{height: "100%"}}>
                            <Grid item width={"100%"}>
                              <Grid item container alignItems="left" direction={"column"} spacing={4} rowSpacing={3}
                                    width={"100%"}>
                                <Grid item container direction={"row"} justifyContent={"space-evenly"}
                                      columnSpacing={4}>
                                  <Grid item xs={8} lg={12} xl={7}>
                                    <Typography className={classes.roleTitle}>
                                      {role["position-name"]}
                                    </Typography>
                                  </Grid>
                                  <Grid item container direction={"column"} xs={4} lg={12} xl={5}
                                        justifyContent={"center"} marginTop={(!xlScreen && largeScreen) ? 3 : 0}
                                        alignContent={(!xlScreen && largeScreen) ? "center" : "right"}>
                                    <Typography className={classes.eligible}
                                                textAlign={(!xlScreen && largeScreen) ? "center" : "right"} xs={12}>
                                      Not Eligible {!(!xlScreen && largeScreen) && role["not-eligible-reason"] ? "" : "(" + reasonMap[role["not-eligible-reason"]] + ")"}
                                    </Typography>
                                    {(role["not-eligible-reason"] && !(!xlScreen && largeScreen)) &&
                                        <Typography className={classes.eligible}
                                                    textAlign={(!xlScreen && largeScreen) ? "center" : "right"}>
                                          ({reasonMap[role["not-eligible-reason"]]})
                                        </Typography>}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Typography className={classes.roleDescription} sx={{whiteSpace: "pre-wrap"}} display={"block"} dangerouslySetInnerHTML={{__html: truncate(role["position-description"].includes("//") ? role["position-description"].substring(0, role["position-description"].indexOf("//")) : role["position-description"], 500)}}>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid item>
                                <Button variant="outlined" className={classes.applyButton}
                                        onClick={() => handleClick(role["position-id"])} size="large">
                                  View Detail and Apply Anyway
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                  ))}
                </Grid>
              </Grid>
          }
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default ApplyCompany;
