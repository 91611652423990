import { useState } from "react"
import erawan_orange from "./../../../assets/erawan_elephant_orange.svg"
import { Typography, Grid, ThemeProvider, Button} from "@mui/material"
import theme from "../../../theme"
import InputField from "../../../common/SACC/CareersFairContext/InputField"
import Label from "../../../common/SACC/CareersFairContext/Label"
import Cookies from "universal-cookie";
import {UUIDv4} from "../../../support";
import { useAuth } from "../../../contexts/AuthContext"

const CompanyLogin = () => {

    const {companyLogin} = useAuth();
    const [email, setEmail] = useState("")
    const [password, setPass] = useState("")
    const [loading, setLoading] = useState(false)

    const cookies = new Cookies();

    function submitHandler(event) {
        event.preventDefault();

        const secret = UUIDv4();
        cookies.set("cSecret", secret, {path:"/"});

        const urlParams = new URLSearchParams(window.location.search);
        const returnPath = urlParams.get('ret');

        setLoading(true);

        companyLogin(email, password,secret).then(async (res) => {
            const data = await res.json()
            console.log(res)
            console.log(data)
            if (res.status === 200) {
                cookies.set("cTokenID", data.data["tokenID"], {path:"/"});
                cookies.set("companyID", data.data["companyID"], {path:"/"});
                console.log(cookies.get("companyID") + " ID");
                console.log(cookies.get("tokenID") + " Token");
                window.location = (returnPath === undefined || returnPath === null) ? "./home" : returnPath;
            } else {
                alert(data.data["reason"]);
                setLoading(false);
            }
        }).catch((error) => {
            console.log(error)
            setLoading(false);
        })
    }

    return(
        <ThemeProvider theme={theme}>
            <Grid container direction="column" justifyContent="center" padding="100px" spacing={4}>
                <Grid container item alignItems="center" spacing={1}>
                    <Grid container item alignItems="center" spacing={2}>
                        <Grid item>
                            <img style={{width : "61px"}} src={"/logo_green.jpg"} alt={"logo"}/>
                        </Grid>
                        <Grid item>  
                            <Typography sx={{fontWeight: "700", fontSize : "48px", color: "rgb(0,44,41)"}} variant="h5">CFAS for Companies</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{fontWeight: "500", fontSize : "24px", color : "#878787"}}>You can sign in to view candidate profile and CV</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <form onSubmit={submitHandler}>
                        <Grid container spacing={5}>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Username </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </Grid>
                            </Grid>
                            <Grid container item direction="column" spacing={1}>
                                <Grid item>
                                    <Label> Password </Label>
                                </Grid>
                                <Grid item>
                                    <InputField fullWidth type="password" value={password} onChange={(e) => setPass(e.target.value)}/>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*    <Button sx={{color : "#878787", padding : 0,fontSize: "20px", fontWeight : "500"}}>Forgot password?</Button>*/}
                                {/*</Grid>*/}
                            </Grid>
                            <Grid container item justifyContent="center">
                                <Button sx={{background :"rgb(0,44,41)", ":hover" : {background: "rgb(0,23,22)"}, color : "white", width : "50%", padding : "10px 0", fontSize : "24px", borderRadius : "15px"}} type="submit"> {loading ? "Logging In" : "Login"} </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid> 
        </ThemeProvider>
    )
}

export default CompanyLogin